import React, { useEffect } from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import { MyBookings as MyBookingsPage } from '../components/MyBookings'
import { isLoggedIn } from '../utils/protectedRoute'
const MyAccount = () => {
	return !isLoggedIn() ? (
		<h1>you are not loggedIn </h1>
	) : (
		<Layout>
			<SEO title='My Bookings' />
			<MyBookingsPage />
		</Layout>
	)
}

export default MyAccount
