import React, { useEffect, useContext } from "react";
import "./style.css";
import { toast } from "react-toastify";
import { navigate } from "gatsby";
import { useLazyQuery } from "@apollo/react-hooks";
import { getCusomterRides } from "./graphql/queries";
import jwtDecode from "jwt-decode";
import { isBrowser } from "../../utils/general";
import { Table } from "reactstrap";
import { ListGroup, ListGroupItem } from "reactstrap";
import Spinner from "../Common/Spinner";
import { LanguageContext } from "../../lang";

const MyBookings = () => {
  const {
    translations: { myBooking },
  } = useContext(LanguageContext);
  const {
    myAccountText,
    myBookingText,
    bookingNumberText,
    outwardText,
    returnRideText,
    passengersText,
    statusText,
    actionText,
    detailBtnText,
    missedBtnText,
    scheduledBtnText,
    completedBtnText,
    cancelText,
    detailText,
  } = myBooking;

  const [getCustomerRideTrigger, { data, loading, error }] = useLazyQuery(
    getCusomterRides
  );
  const openRideDetail = (id) => {
    navigate("/ride-detail", {
      state: {
        rideId: id,
      },
    });
  };
  useEffect(() => {
    let token = isBrowser() ? JSON.parse(localStorage.getItem("token")) : {};
    let sessionToken = isBrowser()
      ? JSON.parse(sessionStorage.getItem("token"))
      : {};
    if (token || sessionToken) {
      const { _id } = jwtDecode(token ? token : sessionToken);
      getCustomerRideTrigger({
        variables: {
          id: _id,
        },
      });
    }
  }, []);
  if (error) {
    toast.error(error.message, {
      autoClose: 1200,
    });
  }
  if (loading) {
    console.log("graphql loading", loading);
  }

  if (data) {
    console.log("data from graphql is", data);
  }
  return (
    <div className="container-fluid d-flex  hm-login-bg justify-content-center align-items-start">
      <div id="dashboard">
        <div className="blueBackgroundHeader"></div>

        <div className="container col-12">
          <div className="row">
            <div
              className="col-12 col-md-3 accAndBookingCard"
              style={{ cursor: "pointer" }}
            >
              <div className="row">
                <div className="col">
                  <ul className="list-group flex-sm-column flex-row justify-content-center my-1">
                    <li
                      className="list-group-item"
                      style={{
                        backgroundColor: "white",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "rgba(8,10,97,1)",
                      }}
                      tag="a"
                      onClick={() => navigate("/my-account")}
                    >
                      {myAccountText}
                    </li>
                    <li
                      className="list-group-item"
                      style={{
                        background: "linear-gradient(90deg,#feb532,#ed5523)",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                      active={true}
                      tag="a"
                      onClick={() => navigate("/my-bookings")}
                    >
                      {myBookingText}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-12 col-md-9 absolutePostionBox py-3">
              <p className="myAccountHeading px-3">
                {myBookingText} ({data && data.customerRides.length})
              </p>
              {loading ? (
                <div className="container-fluid">
                  <Spinner />
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="headingTextStyle">{bookingNumberText}</th>
                      <th className="headingTextStyle">{outwardText}</th>
                      <th className="headingTextStyle">{returnRideText}</th>
                      <th className="headingTextStyle">{passengersText}</th>
                      <th className="headingTextStyle">{statusText}</th>
                      <th className="headingTextStyle">{actionText}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.customerRides.map((ride, i) => (
                        <tr key={ride.id}>
                          <th scope="row">{i}</th>
                          <td>{ride.id.toString().slice(14, 24)}</td>
                          <td>
                            {new Date(
                              parseInt(
                                ride.rideType === "scheduled_ride"
                                  ? ride.pickupDate
                                  : ride.date
                              )
                            ).toLocaleDateString()}
                          </td>
                          <td>{"--"}</td>
                          <td>{ride.numberOfPassenger}</td>
                          <td>
                            <div
                              className={` rideStatus ${
                                ride.status === "missed"
                                  ? "warning"
                                  : ride.status === "completed"
                                  ? "success"
                                  : "info"
                              } `}
                            >
                              <p className="statusText py-2">{ride.status}</p>
                            </div>
                          </td>
                          <td>
                            <div
                              onClick={() => openRideDetail(ride.id)}
                              className="detailButton "
                            >
                              <p className="buttonText py-2">{detailBtnText}</p>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MyBookings };
