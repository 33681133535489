import gql from 'graphql-tag'

export const getCusomterRides = gql`
	query($id: String!) {
		customerRides(id: $id) {
			id
			date
			pickupDate
			pickupLoc
			destLoc
			custPhone
			custFullName
			rideType
			billType
			numberOfPassenger
			totalFare
			status
		}
	}
`
